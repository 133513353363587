
  import UtilityDetailList from '@/components/utility/UtilityDetailList.vue';
  import UtilityHTMLContentRenderer from '@/components/utility/UtilityHTMLContentRenderer.vue';

  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SectionContent',
    components: {
      UtilityDetailList,
      UtilityHTMLContentRenderer,
    },
    props: {
      /**
       * Section heading
       */
      heading: {
        type: String,
        required: true,
      },

      /**
       * Content
       */
      content: {
        type: String,
        default: '',
      },

      /**
       * Detail list
       */
      details: {
        type: Array,
        default: () => [],
      },
    },
  });
