import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "detail-list",
  "data-test": "detail-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UtilityHTMLContentRenderer = _resolveComponent("UtilityHTMLContentRenderer")!

  return (_ctx.items)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createBlock(_component_UtilityHTMLContentRenderer, {
            content: item,
            key: index,
            tag: "li",
            "data-test": "detail-list-item",
            class: _normalizeClass(["detail-list__item", `detail-list__item--${index}`])
          }, null, 8, ["content", "class"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}