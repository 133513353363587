
  import UtilityDetailList from '@/components/utility/UtilityDetailList.vue';
  import UtilityHTMLContentRenderer from '@/components/utility/UtilityHTMLContentRenderer.vue';

  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PositionItem',
    components: {
      UtilityDetailList,
      UtilityHTMLContentRenderer,
    },
    props: {
      /**
       * Company name
       */
      company: {
        type: String,
        required: true,
      },

      /**
       * End date
       */
      description: {
        type: [String, Boolean],
        default: false,
      },

      /**
       * End date
       */
      endDate: {
        type: String,
        required: true,
      },

      /**
       * Position title
       */
      jobTitle: {
        type: String,
        required: true,
      },

      /**
       * Company location
       */
      location: {
        type: String,
        required: true,
      },

      /**
       * Responsibilities
       */
      responsibilities: {
        type: Array,
        required: true,
      },

      /**
       * Start date
       */
      startDate: {
        type: String,
        required: true,
      },
    },
  });
