
  import GlobalFooter from '@/components/global/GlobalFooter.vue';
  import GlobalNavigation from '@/components/global/GlobalNavigation.vue';

  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'App',
    components: {
      GlobalFooter,
      GlobalNavigation,
    },
  });
