
  import { defineComponent } from 'vue';
  import PositionItem from '@/components/position/PositionItem.vue';

  export default defineComponent({
    name: 'PositionGroup',
    components: {
      PositionItem,
    },
    props: {
      /**
       * Collection of data objects to render.
       * Needs to match prop requirements
       * for `PositionItem` component
       *
       * @var {Array} data
       */
      data: {
        type: Array,
        required: true,
      },
    },
  });
