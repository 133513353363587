import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "position-group",
  "data-test": "position-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PositionItem = _resolveComponent("PositionItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createBlock(_component_PositionItem, {
        key: index,
        class: _normalizeClass(["position-group__item", `position-group__item--${index}`]),
        company: item.company,
        description: item.description,
        "end-date": item.endDate,
        "job-title": item.jobTitle,
        location: item.location,
        responsibilities: item.responsibilities,
        "start-date": item.startDate
      }, null, 8, ["class", "company", "description", "end-date", "job-title", "location", "responsibilities", "start-date"]))
    }), 128))
  ]))
}