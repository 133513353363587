
  import { mapGetters } from 'vuex';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'GlobalNavigation',
    computed: {
      ...mapGetters('navigation', {
        primary: 'getPrimary',
        utility: 'getUtility',
      }),
    },
  });
