import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = { class: "navigation__inner" }
const _hoisted_3 = { class: "navigation__primary" }
const _hoisted_4 = ["href", "title", "target"]
const _hoisted_5 = { class: "navigation__utility" }
const _hoisted_6 = ["href", "title", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.primary, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createElementVNode("a", {
              class: "navigation__link",
              href: item.href,
              title: item.name,
              target: item.target
            }, _toDisplayString(item.name), 9, _hoisted_4)
          ]))
        }), 128))
      ]),
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.utility, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createElementVNode("a", {
              class: "navigation__button",
              href: item.href,
              title: item.name,
              target: item.target
            }, _toDisplayString(item.name), 9, _hoisted_6)
          ]))
        }), 128))
      ])
    ])
  ]))
}