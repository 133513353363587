
  import UtilityHTMLContentRenderer from './UtilityHTMLContentRenderer.vue';

  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'UtilityDetailList',
    components: {
      UtilityHTMLContentRenderer,
    },
    props: {
      /**
       * Content containing HTML markup
       */
      items: {
        type: Array,
        default: () => [],
      },
    },
  });
