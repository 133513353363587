
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'UtilityHTMLContentRenderer',
    inheritAttrs: true,
    props: {
      /**
       * Content containing HTML markup
       */
      content: {
        type: String,
        default: null,
      },

      /**
       * Tag name
       */
      tag: {
        type: String,
        default: 'div',
      },
    },
  });
