import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalNavigation = _resolveComponent("GlobalNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_GlobalFooter = _resolveComponent("GlobalFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GlobalNavigation),
    _createElementVNode("main", {
      class: _normalizeClass(["container--inner", `page page--${_ctx.$route.name}`])
    }, [
      _createVNode(_component_router_view)
    ], 2),
    _createVNode(_component_GlobalFooter)
  ], 64))
}