import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "section-content__col section-content__col--first" }
const _hoisted_2 = { "data-test": "section-content-heading" }
const _hoisted_3 = {
  class: "section-content__col section-content__col--second",
  "data-test": "section-content-body"
}
const _hoisted_4 = {
  key: 1,
  class: "section-content__details",
  "data-test": "section-content-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UtilityHTMLContentRenderer = _resolveComponent("UtilityHTMLContentRenderer")!
  const _component_UtilityDetailList = _resolveComponent("UtilityDetailList")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["section-content", `section-content--${_ctx.heading.toLowerCase()}`]),
    "data-test": "section-content"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.heading), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.content)
        ? (_openBlock(), _createBlock(_component_UtilityHTMLContentRenderer, {
            key: 0,
            content: _ctx.content,
            "data-test": "section-content-content"
          }, null, 8, ["content"]))
        : _createCommentVNode("", true),
      (_ctx.details.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_UtilityDetailList, { items: _ctx.details }, null, 8, ["items"])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "main")
    ])
  ], 2))
}