
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SectionHeader',
    props: {
      /**
       * Content object
       */
      content: {
        type: Object,
        required: true,
      },
    },
  });
