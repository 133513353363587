import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent('script'), { type: "application/ld+json" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.structuredData), 1)
      ]),
      _: 1
    }))
  ]))
}