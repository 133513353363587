
  import { mapGetters } from 'vuex';
  import { defineComponent } from 'vue';

  import PositionGroup from '@/components/position/PositionGroup.vue';
  import SectionHeader from '@/components/section/SectionHeader.vue';
  import SectionContent from '@/components/section/SectionContent.vue';
  import StructuredDataPerson from '../components/structuredData/StructuredDataPerson.vue';

  export default defineComponent({
    components: {
      PositionGroup,
      SectionContent,
      SectionHeader,
      StructuredDataPerson,
    },
    computed: {
      ...mapGetters('positions', {
        positions: 'getPositions',
      }),
      ...mapGetters('content', {
        contact: 'getContact',
        primaryContent: 'getPrimaryContent',
        tertiaryContent: 'getTertiaryContent',
      }),
    },
  });
