import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StructuredDataPerson = _resolveComponent("StructuredDataPerson")!
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_SectionContent = _resolveComponent("SectionContent")!
  const _component_PositionGroup = _resolveComponent("PositionGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StructuredDataPerson),
    _createVNode(_component_SectionHeader, { content: _ctx.contact }, null, 8, ["content"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.primaryContent.sections, (section, index) => {
      return (_openBlock(), _createBlock(_component_SectionContent, {
        key: index,
        heading: section.title,
        content: section.content,
        details: section.details
      }, null, 8, ["heading", "content", "details"]))
    }), 128)),
    _createVNode(_component_SectionContent, { heading: "Experience" }, {
      main: _withCtx(() => [
        _createVNode(_component_PositionGroup, { data: _ctx.positions }, null, 8, ["data"])
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tertiaryContent.sections, (section, index) => {
      return (_openBlock(), _createBlock(_component_SectionContent, {
        key: index,
        heading: section.title,
        content: section.content,
        details: section.details
      }, null, 8, ["heading", "content", "details"]))
    }), 128))
  ], 64))
}