
  import { mapGetters } from 'vuex';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'GlobalFooter',
    computed: {
      ...mapGetters('content', {
        email: 'getContactEmail',
        fullName: 'getContactFullName',
      }),
    },
  });
